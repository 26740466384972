import React, { Component } from 'react';
import './index.css';


export default function ActivityComponent(props) {

  return (
    <div className="activityComponent component">
      <header className="activityComponent__head">
        <h3 className="activityComponent__title">{props.activityTitle}</h3>
        <p className="activityComponent__date">{props.activityDate}</p>
      </header>
      <p className="activityComponent__name">{props.activityName}</p>
      <p className="activityComponent__description">
        <strong>Details: </strong><br />
        {props.activityDescription}
      </p>
    </div>
  )

}