import React, { Component } from 'react';
import './App.css';

import Home from './sections/Home';
import About from './sections/About';
import Skills from './sections/Skills';
import Experiences from './sections/Experiences';
import Activities from './sections/Activities';
import Contact from './sections/Contact';
import Uses from './sections/Uses';
import Footer from './sections/Footer';

class App extends Component {
  render() {
    return (
      <main className="App">
        <Home/>
        <Experiences/>
        <Activities/>
        <Uses/>
        <Contact/>
        <Footer/>
      </main>
    );
  }
}

export default App;
