import React, { Component } from 'react';
import './index.css';
import Job from '../../components/Job'

class ExperiencesSection extends Component {
  render() {
    return (
      <section className="section experiencesSection" id="experiencesSection">
        <h2 className="section__title experiencesSection__name">Experiences</h2>
        <article className="section__content experiencesSection__content">
          <Job jobCompany="Immobilien Scout24 - Germany" jobStartDate="November 2021" jobEndDate="Current" jobTitle="Software Engineer" jobDesciption="Atlas" jobSkills="React, AWS" />

          <Job jobCompany="ThoughtWorks - Brazil" jobStartDate="May 2019" jobEndDate="October 2021" jobTitle="Software Developer Consultant" jobDesciption="Worked for 2 different clients." jobSkills="Java Spring, Microservices, NodeJS, Azure Dev Ops " />

          <Job jobCompany="Accenture - Brazil" jobStartDate="Dec 2018" jobEndDate="May 2019" jobTitle="Application Development Analyst " jobDesciption="Development of hybrid applications using Ionic Framework and Salesforce as backend" jobSkills="Ionic Framework3, Angular, Typescript, Javascript, HTML, CSS" />

          <Job jobCompany="Sistema Jornal do Commercio de comunicação - Brazil" jobStartDate="Aug 2016" jobEndDate="Nov 2017" jobTitle="Backend Programmer" jobDesciption="Maintenance and development of new CMS(Content Management System) used by journalists; Maintenance and development of new functionality of the sites, portal and blogs; Development of systems used internally by company members." jobSkills="PHP,Cake PHP Framework,HTML,CSS,Javascript,MySQL" />

          <Job jobCompany="Civis gestão inteligente - Brazil" jobStartDate="Feb 2013" jobEndDate="Jul 2016" jobTitle="Backend Programmer" jobDesciption="Development and maintenance of financial and education management systems" jobSkills="PHP,Cake PHP Framework,HTML,CSS,Javascript,MySQL, PostgreSQL" />
        </article>
      </section>
    );
  }
}
export default ExperiencesSection;