import React, { Component } from 'react';
import './index.css';
import Menu from '../../components/Menu';
import About from '../About';

class HomeSection extends Component {

  scrollTo(e) {
    e.preventDefault();
    document.querySelector('#aboutSection').scrollIntoView({
      behavior: 'smooth' 
    });
  }

  typeWriter(el) {
    const textoArray = el.innerHTML.split('');
    el.innerHTML = '';
    for(let i = 0; i < textoArray.length; i++) {
      setTimeout(() => el.innerHTML += textoArray[i], 200* i);
    }
  }

  componentDidMount(){
    const titulo = document.querySelector('.homeSection__description');
    this.typeWriter(titulo);
    
  }


  render() {

    return (
      <section className="section homeSection" id="homeSection">
       <div className="background"></div>
       <Menu />
       <img src="assets/me.jpeg" alt="Talita Oliveira" className="homeSection__photo" />
       <h1 className="homeSection__name" id="site-title">  &lt;talita-oliveira/&gt; </h1>
       <p className="homeSection__description">Software Engineer</p>
       <p className="homeSection__description2">
         Hello!! My name is <strong className="content__important">Talita Oliveira</strong>, I am a <strong className="content__important">Software Engineer</strong> currently living in <strong className="content__important">Germany - Berlin</strong>.
       </p>
       <div className="bouncy-arrow">
         <a href="#aboutSection" className="scroll" onClick={(e) => this.scrollTo(e)}>
           <i className="fas fa-chevron-circle-down"></i>
         </a>
       </div>
      </section>
    );
  }
}

export default HomeSection;