import React, { Component } from 'react';
import './index.css';

class UsesSection extends Component {
    render() {
        return (
            <section className="section usesSection" id="uses">
                <h2 className="section__title usesSection__name">Uses</h2>
                <div className="usesSection__contentWrapper">
                    <article className="section__content uesesSection__content">
                        <h3 className="usesSection__subtitle">Editor</h3>
                        <p>
                            VS CODE
                            Theme: <strong>Shades of Purple</strong>
                        </p>
                    </article>

                    <article className="section__content uesesSection__content">
                        <h3 className="usesSection__subtitle">Computers</h3>
                        <p>
                            At Work: <strong>Macbook Pro 15'</strong>
                        </p>
                        <p>
                            At Home: <strong>Macbook Pro 13'</strong>
                        </p>
                    </article>

                    <article className="section__content uesesSection__content">
                        <h3 className="usesSection__subtitle">Desktop Apps</h3>
                        <ul>
                            <li>Spotify</li>
                            <li>Todoist</li>
                            <li>Evernote</li>
                            <li>Google Chrome</li>
                        </ul>
                    </article>

                    <article className="section__content uesesSection__content">
                        <h3 className="usesSection__subtitle">Terminal</h3>
                        <p>Hyper + OhMyZSH</p>
                    </article>

                    <small>Under construction...</small>

                </div>
            </section>
        );
    }
}

export default UsesSection;