import React, { Component } from 'react';
import './index.css';
import Activity from '../../components/Activity'

class ActivitiesSection extends Component {
  render() {
    return (
      <section className="section activitiesSection" id="activitiesSection">
        <h2 className="section__title activitiesSection__name">Activities</h2>
        <article className="section__content activitiesSection__content">
            <Activity activityTitle="The Developers Conference" activityName="As Volunteer" activityDate="12 October 2019" activityDescription="Helping the coordinators on the Javascript Trail" />

          <Activity activityTitle="Course Designer Front-End" activityName="Teaching on module FrontEnd" activityDate="Classes in May 2018/ Classes in Oct 2018/ Classes in May 2019/ Classes in Oct 2019" activityDescription="Teaching basics of HTML, CSS and Javascript to a class. The main focus of the course is Design and FrontEnd." />

          <Activity activityTitle="Hackathon UberHack" activityName="Participant as FrontEnd" activityDate="19 and 20 May 2018" activityDescription="The Mobility Hackathon with the challenge of seeking mobility solutions to try to solve traffic problems in our cities." />

          <Activity activityTitle="DevFestXP" activityName="As Volunteer" activityDate="9 Dec. 2017" activityDescription="Helping with the organization while the event was occuring. Was also responsible to announce the speakers to the public before each presentation." />

          <Activity activityTitle="Code In The Dark" activityName="Competitor. 1st Place." activityDate="Dec. 2017" activityDescription="Code in the Dark is a programming competition that must use only HTML and CSS without preview during 15 minutes. The result is only showed at the end of 15 minutes and the public can vote for the one that is closer to the was proposed." />

          <Activity activityTitle="Code In The Dark" activityName="Competitor. 2nd Place." activityDate="Jul. 2017" activityDescription="Code in the Dark is a programming competition that must use only HTML and CSS without preview during 15 minutes. The result is only showed at the end of 15 minutes and the public can vote for the one that is closer to the was proposed." />

          <Activity activityTitle="FEMUGPE - Front End Meetup Group" activityName="Speaker - Talking about ”CSS CUSTOM PROPERTIES”" activityDate="28 Jul. 2017" activityDescription="FEMUGPE is a monthly event for people interested in Front-End technologies. In my talk I showed how use CSS Variables in native Way without any extension." />

          <Activity activityTitle="ENTEC - II Encontro de Tecnologia da Informação of IFPE " activityName="Speaker - Talking about ”Mulher na área de T.I.” (WOMAN IN I.T.)" activityDate="22 Jun. 2017" activityDescription="Technology event for students of IFPE,in my talk I told about the life of a woman in the I.T. area, my life and how I decided to choose this area." />
        </article>
      </section>
    );
  }
}

export default ActivitiesSection;