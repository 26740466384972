import React, { Component } from 'react';
import './index.css';

class FooterSection extends Component {
  render() {
    return (
      <footer className="section footerSection" id="footerSection">
        <p className="footerSection__title">Made with 💜 by Talita Oliveira. 2018.</p>
      </footer>
    );
  }
}

export default FooterSection;