import React from 'react';
import './index.css';

export default function SocialButtonComponent(props) {

  return (
    <a href={props.socialLink} target="_blank" >
      <i className={props.icoFA}></i>
    </a>
  )

}
