import React from 'react';
import './index.css';

class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
            isHome: true
        };
    }

    toggleMenu() {
        this.setState({
            toggle: !this.state.toggle
        });
    }


    render() {


        let { toggle } = this.state;

        return (
            <div className="container">
                <div className="menu" onClick={() => this.toggleMenu()}>
                    <i className={`menu__icon fas ${(toggle === true ? 'fa-times' : 'fa-bars')}`}></i>
                </div>
                <div className={`menuList ${(toggle === true ? 'show' : '')}`}>
                    <ul>
                        <li className="menuList__item">
                            <a href="#homeSection" onClick={(e) => this.toggleMenu()}>Home</a>
                        </li>
                        <li className="menuList__item">
                            <a href="#aboutSection" onClick={(e) => this.toggleMenu()}>About</a>
                        </li>
                        <li className="menuList__item">
                            <a href="#skillsSection" onClick={(e) => this.toggleMenu()}>Skills</a>
                        </li>
                        <li className="menuList__item">
                            <a href="#experiencesSection" onClick={(e) => this.toggleMenu()}>Experiences</a>
                        </li>
                        <li className="menuList__item">
                            <a href="#activitiesSection" onClick={(e) => this.toggleMenu()}>Activities</a>
                        </li>
                        <li className="menuList__item">
                            <a href="#uses" onClick={(e) => this.toggleMenu()}>Uses</a>
                        </li>
                        <li className="menuList__item">
                            <a href="#contactSection" onClick={(e) => this.toggleMenu()}>Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

        )
    }
}

export default Menu;