import React, { Component } from 'react';
import './index.css';
import SocialButton from '../../components/SocialButton';

class ContactSection extends Component {
  render() {
    return (
      <section className="section contactSection" id="contactSection">
        <h2 className="section__title contactSection__name">Contact</h2>
        <article className="section__content contactSection__content">
          <p>Find me on the links below </p>
          <ul className="contactSection__social">
            <li className="social-links social-links__github">
              <SocialButton socialLink="https://github.com/talitaoliveira" icoFA="fab fa-github" />
            </li>
            <li className="social-links social-links__linkedin">
              <SocialButton socialLink="https://br.linkedin.com/in/litaaoliveira" icoFA="fab fa-linkedin" />
            </li>
            <li className="social-links social-links__codepen">
              <SocialButton socialLink="https://codepen.io/talitaoliveira" icoFA="fab fa-codepen" />
            </li>
            <li className="social-links social-links__medium">
              <SocialButton socialLink="https://medium.com/@litaoliveira" icoFA="fab fa-medium" />
            </li>
            <li className="social-links social-links__twitter">
              <SocialButton socialLink="https://twitter.com/liitacherry" icoFA="fab fa-twitter" />
            </li>
          </ul>
        </article>
      </section>
    );
  }
}

export default ContactSection;