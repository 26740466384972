import React, { Component } from 'react';
import './index.css';

import SkillTag from '../SkillTag';

class JobComponent extends Component {

  constructor(props) {
    super(props);
    this.job = props;
    this.state = {
      skills: [],
      description: ''
    }
  }

  componentDidMount() {
    this.separateSkills();
    this.separateDescription();
  }

  separateSkills() {
    let arraySkills = this.job.jobSkills.split(',');

    this.setState({
      skills: arraySkills
    });
  }

  separateDescription() {

    let descriptionSplit = this.job.jobDesciption.split(';');
    let descriptionFormated = '';
    descriptionSplit.forEach((line) => {
      descriptionFormated += `- ${line}\n`;
    });

    this.setState({
      description: descriptionFormated
    });
  }

  render() {

    let { skills } = this.state;
    let { description } = this.state;

    return (

      <div className="jobComponent">
        <header className="jobComponent__head">
          <h3 className="jobComponent__company">{this.job.jobCompany}</h3>
          <p className="jobComponent__duration">{this.job.jobStartDate} - {this.job.jobEndDate}</p>
        </header>
        <strong className="jobComponent__title">{this.job.jobTitle}</strong>
        <div className="jobComponent__skills">
          {skills.map(skill => {
            return (
              <SkillTag key={skill} skillName={skill} />
            )
          })}
        </div>
        <p className="jobComponent__description">
          {description}
        </p>
      </div>
    );
  }
}

export default JobComponent;

