import React, { Component } from 'react';
import './index.css';

import SkillTag from '../../components/SkillTag'
class SkillsSection extends Component {
  render() {
    return (
      <section className="section skillsSection" id="skillsSection">
        <h2 className="section__title skillsSection__name">Skills</h2>
        <div className="skillSection__contentWrapper">
          <article className="section__content skillsSection__content">
            <h3 className="skillsSection__subtitle">Technologies that I know</h3>
            <div>
              <SkillTag skillName="Javascript" />
              <SkillTag skillName="ReactJS" />
              <SkillTag skillName="NodeJS" />
              <SkillTag skillName="Typescript" />
              <SkillTag skillName="Angular" />
              <SkillTag skillName="Ionic Framework 3" />
              <SkillTag skillName="HTML" />
              <SkillTag skillName="CSS" />
              <SkillTag skillName="SASS" />
              <SkillTag skillName="Git" />
              <SkillTag skillName="Bootstrap" />
              <SkillTag skillName="MySQL" />
              <SkillTag skillName="MongoDB" />
              <SkillTag skillName="PHP" />
              <SkillTag skillName="Cake PHP Framework" />
              <SkillTag skillName="JQuery" />
            </div>
          </article>
          <article className="section__content skillsSection__content">
            <h3 className="skillsSection__subtitle">Technologies I want to work with</h3>
            <div>
              <SkillTag skillName="Javascript" />
              <SkillTag skillName="ReactJS" />
              <SkillTag skillName="NodeJS" />
              <SkillTag skillName="Typescript" />
              <SkillTag skillName="HTML" />
              <SkillTag skillName="CSS" />
              <SkillTag skillName="Git" />
              <SkillTag skillName="MongoDB" />
            </div>
          </article>
        </div>
      </section>
    );
  }
}

export default SkillsSection;