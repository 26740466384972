import React from 'react';
import './index.css';

export default function SkillTagComponent(props) {

  return (
      <span className="skillTag">
          {props.skillName}
      </span>
  )

}
